import { useCoreStore } from '~/lib/frontend/core/stores/core'

export const useSpeckleProjectUrl = () => {
  const {
    public: { speckleServerUrl }
  } = useRuntimeConfig()
  const core = useCoreStore()

  return (projectId: string) =>
    new URL(
      core.isFrontend2 ? `/projects/${projectId}` : `/streams/${projectId}`,
      speckleServerUrl
    ).toString()
}

export const useSpeckleModelUrl = () => {
  const {
    public: { speckleServerUrl }
  } = useRuntimeConfig()
  const core = useCoreStore()

  return (projectId: string, modelId: string) =>
    new URL(
      core.isFrontend2
        ? `/projects/${projectId}/models/${modelId}`
        : `/streams/${projectId}/branches/${modelId}`,
      speckleServerUrl
    ).toString()
}

export const useSpeckleVersionUrl = () => {
  const {
    public: { speckleServerUrl }
  } = useRuntimeConfig()
  const core = useCoreStore()

  return (projectId: string, modelId: string, versionId: string) =>
    new URL(
      core.isFrontend2
        ? `/projects/${projectId}/models/${modelId}@${versionId}`
        : `/streams/${projectId}/commits/${versionId}`,
      speckleServerUrl
    ).toString()
}
