<template>
  <div
    :class="`${shadow ? 'shadow' : ''} overflow-x-auto simple-scrollbar sm:rounded-lg`"
  >
    <table class="divide-y divide-foundation-page align-middle text-left min-w-full">
      <thead class="bg-foundation">
        <tr class="text-xs text-foreground-2">
          <th :class="[colClasses, 'w-[100px]']">Status</th>
          <th :class="colClasses">Run Id</th>
          <th :class="[colClasses, 'w-[150px]']">Revision</th>
          <th :class="[colClasses, 'w-[150px]']">Speckle Version</th>
          <th :class="[colClasses, 'w-[150px]']">Date</th>
          <th :class="[colClasses, 'w-[150px]']">Duration</th>
          <th :class="[colClasses, 'w-[100px] text-right']">Actions</th>
        </tr>
      </thead>
      <tbody class="divide-y divide-foundation-disabled bg-foundation">
        <template v-if="runs?.length">
          <AutomationsRunsTableRow
            v-for="run in runs"
            :key="run.runId"
            :run="run"
            @details-click="setDialogRun(run)"
          />
        </template>
        <AutomationsRunsTableRow v-else />
      </tbody>
    </table>
    <AutomationsRunsTableInfoDialog
      v-if="dialogRun"
      v-model:open="dialogOpen"
      :run="dialogRun"
    />
  </div>
</template>
<script setup lang="ts">
import type { GetAutomationRunsItem } from '~/lib/frontend/automations/composables/management'

withDefaults(
  defineProps<{
    runs: GetAutomationRunsItem[]
    shadow?: boolean
  }>(),
  {
    shadow: true
  }
)

const dialogOpen = ref(false)
const dialogRun = ref<GetAutomationRunsItem>()
const colClasses = ref('px-4 py-3')

const setDialogRun = (run?: GetAutomationRunsItem) => {
  if (run) {
    dialogRun.value = run
    dialogOpen.value = true
  } else {
    dialogOpen.value = false
  }
}
</script>
