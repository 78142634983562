import type { ComputedRef } from 'vue'
import type { GetAutomationItem } from '~/lib/frontend/automations/composables/management'

export const useProjectData = (
  automation: ComputedRef<{ projectData: GetAutomationItem['projectData'] }>
) => {
  const invalidAutomationReason = computed(() => {
    const projectInfo = automation.value.projectData
    if (!('errorCause' in projectInfo)) return null

    const errorCause = projectInfo.errorCause
    switch (errorCause) {
      case 'ProjectNotFound':
        return 'The project associated with this automation does not exist anymore.'
      case 'ModelNotFound':
        return 'The model associated with this automation does not exist anymore.'
      case 'ProjectNoAccess':
        return 'You no longer have access to the project associated with this automation.'
      default:
        return 'Unknown error.'
    }
  })

  const projectData = computed(() => {
    const projectInfo = automation.value.projectData
    if ('errorCause' in projectInfo) return null

    return projectInfo
  })

  return {
    invalidAutomationReason,
    projectData
  }
}
