<template>
  <LayoutDialog v-model:open="finalOpen" @fully-closed="emit('fully-closed')">
    <div class="flex flex-col space-y-4">
      <div class="flex items-center space-x-2">
        <h2 class="h5 font-bold block">Run: {{ run.runId }}</h2>
        <CommonBadge v-tippy="statusTooltip" :color-classes="statusBadgeColorClasses">
          {{ statusTitle }}
        </CommonBadge>
      </div>
      <div class="flex flex-col">
        <div class="inline-flex space-x-2">
          <strong>Time started:</strong>
          <span v-tippy="runStartAbsolute">
            {{ runStartRelative }}
          </span>
        </div>
        <div class="inline-flex space-x-2">
          <strong>Duration:</strong>
          <template v-if="durationRelative">
            <span v-tippy="durationAbsolute">
              {{ durationRelative }}
            </span>
          </template>
        </div>
      </div>
      <div class="flex flex-col space-y-2">
        <div class="flex items-center space-x-2">
          <span class="text-foreground">Log output:</span>
          <CommonLoadingIcon v-if="showLoader" size="sm" />
        </div>
        <div>
          <CommonCodeOutput v-if="logsData?.length" :content="logsData" />
          <span v-else-if="areLogsFullyRead" class="italic">No logs found</span>
        </div>
      </div>

      <div class="flex justify-end space-x-2">
        <FormButton :to="viewVersionUrl" external target="_blank">
          View version
        </FormButton>
        <FormButton outlined @click="finalOpen = false">Close</FormButton>
      </div>
    </div>
  </LayoutDialog>
</template>
<script setup lang="ts">
import type { GetAutomationRunsItem } from '~/lib/frontend/automations/composables/management'
import { useGetAutomationRunLogs } from '~/lib/frontend/automations/composables/management'
import { useAutomationRunCore } from '~/lib/frontend/automations/composables/runs'
import { useSpeckleVersionUrl } from '~/lib/frontend/core/composables/route'

const emit = defineEmits<{
  (e: 'update:open', val: boolean): void
  (e: 'fully-closed'): void
}>()

const props = defineProps<{
  open: boolean
  run: GetAutomationRunsItem
}>()

const {
  statusBadgeColorClasses,
  statusTitle,
  statusTooltip,
  runStartRelative,
  runStartAbsolute,
  durationRelative,
  durationAbsolute
} = useAutomationRunCore(computed(() => props.run))
const buildVersionUrl = useSpeckleVersionUrl()

const {
  data: logsData,
  isStreamFinished: areLogsFullyRead,
  loading: logsLoading
} = useGetAutomationRunLogs(
  computed(() => props.run.automationId),
  computed(() => props.run.runId)
)

const showLoader = computed(() => logsLoading.value || !areLogsFullyRead.value)

const finalOpen = computed({
  get: () => props.open,
  set: (value: boolean) => emit('update:open', value)
})

const viewVersionUrl = computed(() => {
  const inputData = props.run.inputData
  if (!inputData) return undefined

  return buildVersionUrl(inputData.projectId, inputData.modelId, inputData.versionId)
})
</script>
