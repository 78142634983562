<template>
  <tr class="text-sm text-foreground">
    <template v-if="run">
      <td :class="[colClasses, 'whitespace-nowrap']">
        <!-- Status -->
        <CommonBadge v-tippy="statusTooltip" :color-classes="statusBadgeColorClasses">
          {{ statusTitle }}
        </CommonBadge>
      </td>
      <td :class="[colClasses, 'whitespace-nowrap']">
        <!-- Run name -->
        {{ run.runId }}
      </td>
      <td :class="[colClasses, 'whitespace-nowrap']">
        <!--Revision id -->
        {{ run.automationVersionId }}
      </td>
      <td :class="[colClasses, 'whitespace-nowrap']">
        <!-- Speckle version -->
        <CommonTextLink
          v-if="versionUrl"
          external
          :to="versionUrl"
          size="sm"
          target="_blank"
        >
          {{ run.inputData?.versionId }}
        </CommonTextLink>
      </td>
      <td :class="[colClasses, 'whitespace-nowrap', 'text-foreground-2']">
        <!-- Start -->
        <span v-tippy="runStartAbsolute">
          {{ runStartRelative }}
        </span>
      </td>
      <td :class="[colClasses, 'whitespace-nowrap', 'text-foreground-2']">
        <!-- Duration -->
        <template v-if="durationRelative">
          <span v-tippy="durationAbsolute">
            {{ durationRelative }}
          </span>
        </template>
      </td>
      <td :class="[colClasses, 'whitespace-nowrap', 'flex justify-end']">
        <!-- Actions -->
        <FormButton
          :disabled="runDataUnavailable"
          size="sm"
          text
          link
          class="ml-1"
          @click="$emit('details-click')"
        >
          View
        </FormButton>
      </td>
    </template>
    <td
      v-else
      :class="[
        colClasses,
        'whitespace-nowrap text-center italic text-xs text-foreground-2'
      ]"
      colspan="6"
    >
      No runs have been executed yet for this automation
    </td>
  </tr>
</template>
<script setup lang="ts">
import type { GetAutomationRunsItem } from '~/lib/frontend/automations/composables/management'
import { useAutomationRunCore } from '~/lib/frontend/automations/composables/runs'
import { useSpeckleVersionUrl } from '~/lib/frontend/core/composables/route'

defineEmits<{
  (e: 'details-click'): void
}>()

const props = defineProps<{
  run?: GetAutomationRunsItem
}>()

const {
  statusBadgeColorClasses,
  runStartAbsolute,
  runStartRelative,
  durationAbsolute,
  durationRelative,
  statusTitle,
  statusTooltip
} = useAutomationRunCore(computed(() => props.run))

const buildVersionUrl = useSpeckleVersionUrl()

const versionUrl = computed(() => {
  if (!props.run?.inputData?.versionId) return undefined
  return buildVersionUrl(
    props.run.inputData.projectId,
    props.run.inputData.modelId,
    props.run.inputData.versionId
  )
})

const runDataUnavailable = computed(() => statusTitle.value === 'PENDING')

const colClasses = ref('px-4 py-3')
</script>
