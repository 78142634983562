import type { Optional } from '@speckle/shared'
import type { MaybeRef } from '@vueuse/core'
import dayjs from 'dayjs'
import type { GetAutomationRunsItem } from '~/lib/frontend/automations/composables/management'
import { useReactiveNowDate } from '~/lib/frontend/common/composables/dom'

const durationFormat = (duration: ReturnType<typeof dayjs.duration>) => {
  let format = 'ss[s]'
  if (duration.minutes() > 0) format = 'mm[m] ' + format
  if (duration.hours() > 0) format = 'HH[h] ' + format
  if (duration.days() > 0) format = 'D [days] ' + format
  if (duration.months() > 0) format = 'M [months] ' + format
  if (duration.years() > 0) format = 'Y [years] ' + format

  return format
}

/**
 * Run can be undefined if rendering the "no runs found" table row
 */
export function useAutomationRunCore(run: MaybeRef<Optional<GetAutomationRunsItem>>) {
  const now = useReactiveNowDate()

  const statusBadgeColorClasses = computed(() => {
    const classParts = ['w-20 justify-center']
    const status = unref(run)?.status

    switch (status) {
      case 'pending':
        classParts.push('bg-warning-lighter text-warning-darker')
        break
      case 'running':
        classParts.push('bg-info-lighter text-info-darker')
        break
      case 'failure':
      case 'cancelled':
      case 'timeout':
        classParts.push('bg-danger-lighter text-danger-darker')
        break
      case 'success':
        classParts.push('bg-success-lighter text-success-darker')
        break
    }

    return classParts.join(' ')
  })

  const runStartRelative = computed(() => dayjs(unref(run)?.createdAt).fromNow())
  const runStartAbsolute = computed(() =>
    dayjs(unref(run)?.createdAt).format('MMMM D, YYYY - hh:mm:ss Z')
  )

  const durationRelative = computed(() => {
    const safeRun = unref(run)
    if (!safeRun) return undefined

    const start = safeRun.createdAt
    const end =
      safeRun.status === 'running'
        ? unref(now)
        : ['pending'].includes(safeRun.status)
          ? undefined
          : safeRun.statusUpdatedAt
    if (!end) return undefined

    const diff = dayjs(end).diff(dayjs(start))
    const duration = dayjs.duration(diff)
    const format = durationFormat(duration)

    if (duration.days() > 0) return dayjs.duration(diff).humanize()
    return dayjs.duration(diff).format(format)
  })

  const durationAbsolute = computed(() => {
    const safeRun = unref(run)
    if (!safeRun) return undefined

    const start = safeRun.createdAt
    const end =
      safeRun.status === 'running'
        ? unref(now)
        : ['pending'].includes(safeRun.status)
          ? undefined
          : safeRun.statusUpdatedAt
    if (!end) return undefined

    const duration = dayjs.duration(dayjs(end).diff(dayjs(start)))
    const format = durationFormat(duration)
    return duration.format(format)
  })

  const statusTitle = computed(() => unref(run)?.status.toUpperCase())
  const statusTooltip = computed(() => unref(run)?.reason)

  return {
    statusBadgeColorClasses,
    runStartRelative,
    runStartAbsolute,
    durationAbsolute,
    durationRelative,
    statusTitle,
    statusTooltip
  }
}
